/* eslint-disable no-console */
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import ThemeProvider from './theme/ThemeProvider';
import LoginWrapper from './loginWrapper';

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      console.log(`Graphql error ${err.message}`, { type: 'error', err });
    });
    if (networkError) {
      graphQLErrors.forEach(({ message }) => {
        console.log(`Network error ${message}`, { type: 'error' });
      });
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = from([
  errorLink,
  new HttpLink({
    uri:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_GRAPHQL_API
        : 'http://localhost:3080/graphql',
  }),
]);
const appoloClientInstance = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});
const App = () => {
  const toastProps: ToastContainerProps = {
    position: 'top-center',
    autoClose: 2500,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: false,
    limit: 1,
  };

  return (
    <ApolloProvider client={appoloClientInstance}>
      <ToastContainer {...toastProps} />
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <LoginWrapper />
          {/* <Status500 /> */}
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
