/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const GET_UNVALID_USERS = gql`
  query {
    getUnvalidUsers {
      _id
      userEmail
      userPhoneNumber
      createdAt
    }
  }
`;
export const DELETE_UNVALID_USER = gql`
  mutation ($id: ID) {
    deleteUnverifiedUser(id: $id) {
      _id
    }
  }
`;
export const REGISTER_ADMIN = gql`
  mutation (
    $userName: String
    $userEmail: String
    $userPassword: String
    $userPhoneNumber: String
    $roleId: ID
  ) {
    registerAdmin(
      userName: $userName
      userEmail: $userEmail
      userPassword: $userPassword
      userPhoneNumber: $userPhoneNumber
      roleId: $roleId
    )
  }
`;
export const LOGIN_ADMIN = gql`
  query ($userEmail: String, $userPassword: String) {
    loginAdmin(userEmail: $userEmail, userPassword: $userPassword) {
      status
      token
      message
      user {
        _id
        userName
        userEmail
        userPhoneNumber
        admin {
          _id
          isDeleted
          adminRole {
            roleName
            permissions {
              requests {
                shops
                registrations
                products
              }
              statics {
                categories
                sizes
                colors
                cities
                returnDurations
                termsAndConditions
                warrantyDurations
              }
              suppliers {
                verified
                unverified
                delete
              }
              admins {
                read
                delete
              }
            }
          }
        }
      }
    }
  }
`;
export const VERIFY_TOKEN = gql`
  {
    verifyToken {
      _id
      userName
      userEmail
      userPhoneNumber
      admin {
        _id
        isDeleted
        adminRole {
          roleName
          permissions {
            requests {
              shops
              registrations
              products
            }
            statics {
              categories
              sizes
              colors
              cities
              returnDurations
              termsAndConditions
              warrantyDurations
            }
            suppliers {
              verified
              unverified
              delete
            }
            admins {
              read
              delete
            }
            orders {
              read
              delete
              update
            }
          }
        }
      }
    }
  }
`;
export const GET_ALL_ADMINS = gql`
  query ($page: Int, $filter: String, $sort: Int, $sortBy: String, $status: String) {
    getAllAdmins(page: $page, filter: $filter, sort: $sort, sortBy: $sortBy, status: $status) {
      totalPages
      hasNextPage
      page
      items {
        _id
        adminRole {
          roleName
          _id
        }
        userId {
          userName
          userEmail
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const GET_ADMINS_NUMBER = gql`
  {
    getNumberOfAdmins
  }
`;
export const REGISTER_COURRIER = gql`
  mutation ($userEmail: String, $userPassword: String) {
    registerCourrier(userEmail: $userEmail, userPassword: $userPassword) {
      status
      message
      user {
        _id
        userName
        userEmail
        userPassword
      }
    }
  }
`;
