/* eslint-disable max-lines */
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const TermsAndConditions = Loader(lazy(() => import('src/content/pages/termsAndConditions')));
const ContactInfo = Loader(lazy(() => import('src/content/pages/ContactInfo')));
const Settings = Loader(lazy(() => import('src/content/pages/Settings')));
const FAQ = Loader(lazy(() => import('src/content/pages/FAQ')));

// const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const ManagementUserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings')),
);
const SignIn = Loader(lazy(() => import('src/content/SignIn')));
const OrdersTabsPage = Loader(
  lazy(() => import('./content/applications/Orders/MainOrdersPage/OrdersTabsPage')),
);
const ShipmentsPage = Loader(
  lazy(() => import('./content/applications/Shipments/ShipmentsPage')),
);
const FinancesOverview = Loader(
  lazy(() => import('./content/applications/Orders/FinancesOverview')),
);
const CouriersFinancesOverview = Loader(
  lazy(() => import('./content/applications/Orders/CourierFinancesOverview')),
);
const FeesManagement = Loader(
  lazy(() => import('./content/applications/Orders/FeesManagement')),
);
const OrderDetails = Loader(
  lazy(() => import('./content/applications/Orders/OrderDetails/OrderDetailsContainer')),
);
const WithdrawalsTabsPage = Loader(
  lazy(() => import('./content/applications/Orders/Withdrawals/WithdrawalsTabsPage')),
);
const RefundsTabsPage = Loader(
  lazy(() => import('./content/applications/Orders/Refunds/RefundsTabsPage')),
);
const AddEmployeesFormPage = Loader(
  lazy(() => import('src/content/applications/Users/employees/AddEmployeesFormPage')),
);
const CataloguesList = Loader(
  lazy(() => import('src/content/applications/Users/catalogues/CataloguesList')),
);
const AddCourriersFormPage = Loader(
  lazy(() => import('src/content/applications/Users/courriers/AddCourrierFormPage')),
);
const AdminRolesPage = Loader(lazy(() => import('src/content/applications/Users/adminRoles')));
const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
const ProductDetails = Loader(
  lazy(() => import('src/content/applications/Users/products/productDetails')),
);
const Users = Loader(lazy(() => import('src/content/applications/Users/UsersManagement')));
const CouriersTabsPage = Loader(
  lazy(
    () => import('src/content/applications/Users/courriers/CouriersRequests/CouriersTabsPage'),
  ),
);
const SuppliersTabsPage = Loader(
  lazy(() => import('src/content/applications/Users/suppliers/SuppliersTabsPage')),
);
const VerifiedSuppliersTable = Loader(
  lazy(
    () =>
      import(
        'src/content/applications/Users/suppliers/VerifiedSuppliers/VerifiedSuppliersTable'
      ),
  ),
);
const CostumersTable = Loader(
  lazy(() => import('src/content/applications/Users/customers/CostumersTable')),
);
const AdminsTable = Loader(
  lazy(() => import('src/content/applications/Users/employees/AdminsTable')),
);
const UnverifiedUsersContainer = Loader(
  lazy(() => import('src/content/applications/Users/suppliers/UnverifiedUsersContainer')),
);
const ShopsTable = Loader(
  lazy(() => import('src/content/applications/Users/suppliers/shops/ShopTable')),
);
const ProductsTable = Loader(
  lazy(() => import('src/content/applications/Users/products/productsRequests/productTable')),
);
const CouriersTable = Loader(
  lazy(() => import('src/content/applications/Users/courriers/CouriersTable')),
);
const ShopDetails = Loader(
  lazy(() => import('src/content/applications/Users/suppliers/shops/ShopDetails')),
);
const CourierProfile = Loader(
  lazy(() => import('src/content/applications/Users/courriers/CourierProfile')),
);
const ProductOptions = Loader(
  lazy(() => import('src/content/applications/Users/products/productOptions/Index')),
);
const DeliveryOptions = Loader(
  lazy(() => import('src/content/applications/Users/deliveryOptions/Index')),
);
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));

const Courierswithdrawals = Loader(
  lazy(() => import('src/content/applications/Users/courriers/Courierswithdrawals')),
);
// Components

const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="management/users" replace />,
      },
      {
        path: 'signin',
        element: <SignIn />,
      },
      {
        path: 'unauthorized',
        element: <Status500 />,
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          {
            path: '500',
            element: <Status500 />,
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />,
      },
      {
        path: 'crypto',
        element: <Crypto />,
      },
      {
        path: 'messenger',
        element: <Messenger />,
      },
    ],
  },
  {
    path: 'contact',
    element: <SidebarLayout />,
    children: [{ path: '', element: <ContactInfo /> }],
  },
  {
    path: 'settings',
    element: <SidebarLayout />,
    children: [{ path: '', element: <Settings /> }],
  },
  {
    path: 'terms-and-conditions',
    element: <SidebarLayout />,
    children: [{ path: '', element: <TermsAndConditions /> }],
  },
  {
    path: 'frequently-asked-questions',
    element: <SidebarLayout />,
    children: [{ path: '', element: <FAQ /> }],
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="users" replace />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'unvalid-users',
        element: <UnverifiedUsersContainer />,
      },
      {
        path: 'products-options',
        element: <ProductOptions />,
      },
      {
        path: 'delivery-options',
        element: <DeliveryOptions />,
      },
      {
        path: 'admin',
        element: <ManagementUserSettings />,
      },
      {
        path: 'products',
        element: <ProductsTable />,
      },
      {
        path: 'catalogues',
        element: <CataloguesList />,
      },
      {
        path: 'product-details/:id',
        element: <ProductDetails />,
      },
      {
        path: 'orders',
        element: <OrdersTabsPage />,
      },
      {
        path: 'order-details/:id',
        element: <OrderDetails />,
      },
      {
        path: 'costumers',
        element: <CostumersTable />,
      },
      {
        path: 'finances',
        children: [
          { path: 'orders', element: <OrdersTabsPage /> },
          { path: 'shipments', element: <ShipmentsPage /> },
          { path: 'overview', element: <FinancesOverview /> },
          { path: 'fees', element: <FeesManagement /> },
          { path: 'withdrawals', element: <WithdrawalsTabsPage /> },
          { path: 'refunds', element: <RefundsTabsPage /> },
        ],
      },
      {
        path: 'suppliers',
        children: [
          { path: 'all', element: <VerifiedSuppliersTable /> },
          { path: 'requests', element: <SuppliersTabsPage /> },
        ],
      },
      {
        path: 'admins',
        children: [
          { path: 'all', element: <AdminsTable /> },
          { path: 'add', element: <AddEmployeesFormPage /> },
          { path: 'roles', element: <AdminRolesPage /> },
        ],
      },
      {
        path: 'couriers',
        children: [
          { path: 'all', element: <CouriersTable /> },
          { path: 'add', element: <AddCourriersFormPage /> },
          { path: 'roles', element: <AdminRolesPage /> },
          { path: 'requests', element: <CouriersTabsPage /> },
          { path: 'profile/:id', element: <CourierProfile /> },
          { path: 'finances/overview', element: <CouriersFinancesOverview /> },
          { path: 'couriers-withdrawals', element: <Courierswithdrawals /> },
        ],
      },
      {
        path: 'shops',
        element: <ShopsTable />,
      },
      {
        path: 'shops/details/:id',
        element: <ShopDetails />,
      },

      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details/:id',
            element: <UserProfile />,
          },
          {
            path: 'settings',
            element: <UserSettings />,
          },
        ],
      },
    ],
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />,
      },
      {
        path: 'buttons',
        element: <Buttons />,
      },
      {
        path: 'modals',
        element: <Modals />,
      },
      {
        path: 'accordions',
        element: <Accordions />,
      },
      {
        path: 'tabs',
        element: <Tabs />,
      },
      {
        path: 'badges',
        element: <Badges />,
      },
      {
        path: 'tooltips',
        element: <Tooltips />,
      },
      {
        path: 'avatars',
        element: <Avatars />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'forms',
        element: <Forms />,
      },
    ],
  },
];

export default routes;
