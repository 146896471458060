import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { useEffect } from 'react';
import { VERIFY_TOKEN } from '../graphql/users/usersQueries';
import { IUser } from '../models/IUser';
const LoginWrapper = () => {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const [getCurrentUser] = useLazyQuery<{
    verifyToken: IUser;
  }>(VERIFY_TOKEN);

  useEffect(() => {
    getCurrentUser({
      onCompleted: (res) => {
        if (res?.verifyToken && !res?.verifyToken?._id) navigate('/signin');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return content;
};

export default LoginWrapper;
